import { createMedia } from "@artsy/fresnel";

export const breakpoints = {
  mobile: 320,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1200,
};

const QueryBreakpoints = createMedia({
  breakpoints,
});

// Generate CSS to be injected into the head
export const mediaStyles = QueryBreakpoints.createMediaStyle();

export const { Media, MediaContextProvider } = QueryBreakpoints;
